import jsPDF from 'jspdf';
import { Experience } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';
import { regularFontFamily, regularFontSize } from '../classicPDFproperty';

export const experienceItem = (
  experience: Experience,
  doc: jsPDF,
  startX: number,
  startY: number
) => {
  let nextLine = startY;

  doc.setFont(regularFontFamily, 'italic');
  doc.setFontSize(11);
  if (nextLine >= 782) {
    doc.addPage();
    nextLine = 50;
  }
  doc.text(`${experience.designation}`, startX, nextLine, {
    align: 'left'
  });

  doc.setFontSize(regularFontSize);

  nextLine += 20;

  if (experience.present) {
    doc.text(`${experience.startYear} - Present ${experience.companyName}`, startX, nextLine, {
      align: 'left'
    });
  } else {
    doc.text(
      `From ${experience.startYear} to ${experience.endYear} ${experience.companyName}`,
      startX,
      nextLine,
      {
        align: 'left'
      }
    );
  }

  nextLine += 20;
  doc.setFont(regularFontFamily, 'normal');

  nextLine = addParagraphText(
    `${experience.moredetails}`,
    'justify',
    startX,
    nextLine,
    300,
    15,
    70,
    doc
  );

  return nextLine;
};
