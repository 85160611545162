export const InputStyles = {
  baseStyle: {
    borderRadius: 'sm',
    color: '#ffffff',
    _focus: {},
    _hover: {}
  },
  sizes: {},
  variants: {
    formInput: {
      field: {
        fontSize: '10px',
        fontWeight: 300,
        border: '1px solid #c4c1c1',
        borderRadius: '2px',
        h: '20px',
        lineHeight: '12px',
        paddingY: '5px',
        px: '5px',

        _placeholder: {
          fontSize: '10px'
        },
      }
    }
  }
};
