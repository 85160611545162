import { FC } from 'react';
import { Box, Divider, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {}

const Personalnfo: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;

  return (
    <VStack align="center" spacing=".5rem">
      {/* <Divider
        orientation="horizontal"
        bg="#f50"
        height={1.5}
        borderWidth={0}
      /> */}
      <Flex
        // flex={1}
        width="100%"
        w="100%"
        p={4}
        pl="2rem"
        pr="2rem"
        bgColor={'#870000'}
      >
        <Box flex={0.3} pl={10}>
          <Image
            borderRadius="full"
            boxSize="100px"
            src="https://bit.ly/dan-abramov"
            alt="Dan Abramov"
          />
        </Box>
        <Box flex={1} width="100%">
          <Text fontSize={30} noOfLines={1} textAlign="left" color="#ffffff">
            {realUser.firstName !== ''
              ? realUser.firstName + ' ' + realUser.lastName
              : defaultUser.firstName + ' ' + defaultUser.lastName}
          </Text>
          <Text fontSize={13} noOfLines={1} textAlign="left" color="#ffffff">
            {realUser.jobTitle !== ''
              ? realUser.jobTitle
              : defaultUser.jobTitle}
          </Text>
        </Box>
        {/* <Box flex={1}>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.email !== "" ? realUser.email : defaultUser.email}
          </Text>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.phone !== "" ? realUser.phone : defaultUser.phone}
          </Text>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.address !== "" ? realUser.address : defaultUser.address}
          </Text>
        </Box> */}
      </Flex>
      {/* <Flex w="100%" pl="2rem" pr="2rem">
        <Divider
          orientation="horizontal"
          bg="#555"
          height={0.3}
          borderWidth={0}
        />
      </Flex> */}
    </VStack>
  );
};

export default Personalnfo;
