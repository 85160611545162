import jsPDF from 'jspdf';
import { User } from 'types/User';
import {
  regularFontFamily,
  regularFontSize,
  titleFontFamily,
  titleFontSize
} from './classicPDFproperty';

export const header = (
  user: User,
  doc: jsPDF,
  startX: number,
  startY: number,
  pageWidth: number
) => {
  let nextLine = startY;

  doc.setFont(titleFontFamily);
  doc.setFontSize(titleFontSize);
  doc.text(user.firstName + ' ' + user.lastName, startX, 58, { align: 'left' });

  doc.setFont(regularFontFamily);
  doc.setFontSize(regularFontSize);
  doc.text(user.workExperience.length > 0 ? user.workExperience[0].designation : '', startX, 80, {
    align: 'left'
  });

  doc.text(user.email, startX + pageWidth, startY, {
    align: 'right'
  });
  doc.text(user.phone, startX + pageWidth, startY + 19, {
    align: 'right'
  });
  doc.text(user.address, startX + pageWidth, startY + 34, {
    align: 'right'
  });

  nextLine += 20;
  return nextLine;
};
