import jsPDF from 'jspdf';
import { User } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const skills = (
  user: User,
  doc: jsPDF,
  startX: number,
  startY: number,
  maxWidth: number
) => {
  let nextLine = startY;

  doc.setFontSize(12);
  doc.text('SKILLS', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 25;

  user.skills.forEach(skill => {
    nextLine = addParagraphText(`\u2022 ${skill}`, 'left', startX, nextLine, maxWidth, 15, 40, doc);
  });
  return nextLine;
};
