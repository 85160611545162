import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRealUser, updateUserImageUrl } from 'state/cv/cvState';
import { IRootState } from 'state/rootState';
import PersonalInfo from './PersonalInfo';
import WorkExperience from './workExperience/WorkExperience';
import EducationDetails from './EducationDetails/EducationDetails';
import SkillsAndAccomplishment from './SkillsAndAccomplishment/SkillsAndAccomplishment';
import AppImageUpload from 'components/AppImageUpload/AppImageUpload';

type Props = {};

export default function UserDetailCollectorForm({}: Props) {
  const cvState = useSelector((state: IRootState) => state.cv);
  const realUser = useSelector((state: IRootState) => state.cv.realUser);
  const dispatch = useDispatch();
  const [preEditText, setPreEditText] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>();

  useEffect(() => {
    if (!selectedFile) {
      dispatch(updateUserImageUrl(undefined));
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    dispatch(updateUserImageUrl(objectUrl));

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const onUserFormChange = (field: string, value: any) => {
    const _user = { ...realUser, [field]: value };
    dispatch(updateRealUser(_user));
  };

  const onFormFieldFocus = (field: string, value: string) => {
    setPreEditText(value);
    onUserFormChange(field, '');
  };

  const onFormFieldPostEdit = (field: string, value: string) => {
    if (value === '') {
      onUserFormChange(field, preEditText);
    }
  };

  return (
    <>
      {cvState.cvProps.hasImage && (
        <Flex justify="center">
          {!selectedFile && <AppImageUpload onChange={onSelectFile} />}
          {selectedFile && <img src={cvState.userImageUrl} width={100} height={100} />}
        </Flex>
      )}

      <PersonalInfo onUserFormChange={onUserFormChange} realUser={realUser} />
      <WorkExperience onUserFormChange={onUserFormChange} realUser={realUser} />
      <EducationDetails onUserFormChange={onUserFormChange} realUser={realUser} />
      <SkillsAndAccomplishment onUserFormChange={onUserFormChange} realUser={realUser} />
    </>
  );
}
