import { FC } from 'react';
import Personalnfo from './Personalnfo';
import EducationDetails from './Educations/EducationDetails';
import { Box, Button, Flex, Heading, HStack, Image, Text } from '@chakra-ui/react';
import WorkExperienceDetails from './WorkExperience/WorkExperienceDetails';
import SkillsDetails from './Skills/SkillsDetails';
import AccomplishmentsDetails from './Accomplishments/AccomplishmentsDetails';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';
import { classicProCVpdf } from 'components/Pdf';

interface Props {}

const ClassicProCvHtml: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;

  const exportPDF = async () => {
    classicProCVpdf({ user: realUser, imageUrl: cv.userImageUrl });
  };

  return (
    <>
      <HStack justify="end">
        <Button onClick={exportPDF} colorScheme="teal" size="sm">
          Classic Pro CV Download
        </Button>
      </HStack>
      <Box fontSize="12px" bg="#fff" color="#555">
        <Box width="100%" border="1px solid #edeaea" pb={2}>
          {/* <Personalnfo /> */}
          <Flex>
            <Box flex={2} paddingLeft="2rem" paddingRight="2rem" bg={'blue.900'}>
              <Image
                src={cv.userImageUrl ? cv.userImageUrl : 'https://bit.ly/dan-abramov'}
                alt="cm maker online user image"
              />
              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                {realUser.firstName !== ''
                  ? realUser.firstName + ' ' + realUser.lastName
                  : defaultUser.firstName + ' ' + defaultUser.lastName}
              </Heading>

              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                {realUser.jobTitle !== '' ? realUser.jobTitle : defaultUser.jobTitle}
              </Heading>

              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                ABOUT ME
              </Heading>
              <Text as="h2" textAlign="justify" paddingTop="1rem" color="#555">
                {realUser.summary !== '' ? realUser.summary : defaultUser.summary}
              </Text>
              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                SKILLS
              </Heading>
              <SkillsDetails />
              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                ACCOMPLISHMENT
              </Heading>
              <AccomplishmentsDetails />
            </Box>
            <Box flex={5} paddingLeft="2rem">
              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                WORK EXPERIENCE
              </Heading>
              <WorkExperienceDetails />
              <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
                EDUCATION
              </Heading>
              <EducationDetails />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ClassicProCvHtml;
