import jsPDF from 'jspdf';
import { User } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const reference = (user: User, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text('REFERENCES', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);
  doc.setTextColor("#555");

  nextLine += 25;

  let columXStart = startX;
  let columYStart = nextLine;

  user.reference?.forEach(reference => {
    doc.setFontSize(9);
    doc.text(`${reference.title}`, columXStart, columYStart, {
      align: 'left'
    });
    doc.setFontSize(8);

    columYStart += 20;

    addParagraphText(`${reference.detail}`, 'left', columXStart, columYStart, 150, 16, 40, doc);
    columYStart = nextLine;
    columXStart += 160;
  });

  return nextLine;
};
