import React, { useRef, FC } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import { ContactUs } from 'utills/contact';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const DrawerContactUs: FC<Props> = ({ isOpen, onOpen, onClose }) => {
  const btnRef = useRef<any>();

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#4A154B">Join us</DrawerHeader>
          <DrawerBody>
            <ContactUs />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerContactUs;
