export const ButtonStyles = {
  baseStyle: {
    color: "#ffffff",
    _focus: {},
    _hover: {},
  },
  sizes: {
    md: {},
    lg: {},
  },
  variants: {
    primary: {
      backgroundColor: "#4A154B",
      borderRadius: "20px",
      width: "140px",
      height: "38px",
    },
    secondary: {
      backgroundColor: "#DB9734",
      borderRadius: "20px",
      width: "210px",
      height: "38px",
    },
    solid: {},
    outline: {},
  },
};
