export const defaultUser = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  address: '',
  email: '',
  phone: '',
  summary: '',
  educations: [],
  workExperience: [],
  skills: [],
  accomplishments: [],
  reference: []
};
export const realUser = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  address: '',
  email: '',
  phone: '',
  summary: '',
  educations: [],
  workExperience: [],
  skills: [],
  accomplishments: [],
  reference: []
};
