import jsPDF from 'jspdf';
import { Education } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';
import { regularFontFamily, regularFontSize } from '../classicPDFproperty';

export const educationItem = (education: Education, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  doc.setFont(regularFontFamily, 'italic');
  doc.setFontSize(regularFontSize)
  doc.text(`${education.school}`, startX, nextLine, {
    align: 'left'
  });

  nextLine += 20;

  doc.setFont(regularFontFamily, 'normal');
  nextLine = addParagraphText(`${education.course}`, 'left', startX, nextLine, 150, 15, 70, doc);

  nextLine += 5;
  return nextLine;
};
