import { line } from 'components/Line/line';
import jsPDF from 'jspdf';
import { Education } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const educationItem = (education: Education, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  line({
    doc,
    startPoint: { x: startX, y: nextLine },
    length: { w: 65, h: 1 },
    color: '#b3acb1'
  });

  nextLine += 20;

  doc.setFontSize(10);
  if (education.present) {
    doc.text(`${education.startYear} - Present`, startX, nextLine, {
      align: 'left'
    });
  } else {
    doc.text(`${education.startYear} - ${education.endYear}`, startX, nextLine, {
      align: 'left'
    });
  }
  doc.setFontSize(8);

  nextLine += 20;

  doc.text(education.school, startX, nextLine, {
    align: 'left',
    maxWidth: 100
  });

  doc.setFontSize(9);
  doc.setTextColor('#000');
  doc.text(`${education.course}`, startX + 100, startY, {
    align: 'left'
  });
  doc.setFontSize(8);
  doc.setTextColor('#555');

  nextLine = startY + 25;

  nextLine = addParagraphText(
    `${education.moreDetails}`,
    'justify',
    startX + 100,
    nextLine,
    240,
    15,
    70,
    doc
  );

  return nextLine;
};
