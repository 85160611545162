import { line } from 'components/Line/line';
import jsPDF from 'jspdf';
import { Experience } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const experienceItem = (
  experience: Experience,
  doc: jsPDF,
  startX: number,
  startY: number
) => {
  let nextLine = startY;

  line({
    doc,
    startPoint: { x: startX, y: nextLine },
    length: { w: 65, h: 1 },
    color: '#b3acb1'
  });

  nextLine += 20;

  doc.setFontSize(10);
  if (experience.present) {
    doc.text(`${experience.startYear} - Present`, startX, nextLine, {
      align: 'left'
    });
  } else {
    doc.text(`${experience.startYear} - ${experience.endYear}`, startX, nextLine, {
      align: 'left'
    });
  }
  doc.setFontSize(8);

  nextLine += 20;

  doc.text(experience.companyName, startX, nextLine, {
    align: 'left',
    maxWidth: 100
  });

  doc.setFontSize(9);
  doc.setTextColor('#000');
  doc.text(`${experience.designation.toUpperCase()}`, startX + 100, startY, {
    align: 'left'
  });
  doc.setFontSize(8);
  doc.setTextColor('#555');

  nextLine = startY + 25;

  nextLine = addParagraphText(
    `${experience.moredetails}`,
    'justify',
    startX + 100,
    nextLine,
    240,
    15,
    70,
    doc
  );

  return nextLine;
};
