import jsPDF from 'jspdf';
import { addParagraphText } from 'utills/jsPdf';
import {
  regularFontFamily,
  regularFontSize,
  subTitleFontFamily,
  subTitleFontSize
} from './classicPDFproperty';

export const summary = (
  userSummary: string,
  doc: jsPDF,
  startX: number,
  startY: number,
  pageWidth: number
) => {
  let nextLine = startY;

  doc.setFontSize(subTitleFontSize);
  doc.setFont(subTitleFontFamily);
  doc.text('ABOUT ME', startX, nextLine, {
    align: 'left'
  });

  nextLine += 25;

  doc.setFont(regularFontFamily);
  doc.setFontSize(regularFontSize);
  nextLine = addParagraphText(userSummary, 'justify', startX, nextLine, 300, 15, 120, doc);

  nextLine += 20;
  return nextLine;
};
