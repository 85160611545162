import jsPDF from 'jspdf';
import { User } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const reference = (user: User, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  doc.setFontSize(12);
  doc.text('REFERENCE', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 25;

  user.reference?.forEach(reference => {
    doc.setFontSize(9);
    doc.text(`${reference.title}`, startX, nextLine, {
      align: 'left'
    });
    doc.setFontSize(8);

    nextLine += 20;

    nextLine = addParagraphText(`${reference.detail}`, 'left', startX, nextLine, 150, 16, 40, doc);
  });

  return nextLine;
};
