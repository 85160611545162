import { Box, Button, Divider, FormLabel, HStack, Input, Textarea, VStack } from '@chakra-ui/react';
import AppText from 'components/AppText';
import { FC } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateRealUser } from 'state/cv/cvState';
import { Education, User } from 'types/User';

interface Props {
  onUserFormChange: (field: string, value: string | Education[]) => void;
  realUser: User;
}

const EducationDetails: FC<Props> = ({ realUser, onUserFormChange }) => {
  const dispatch = useDispatch();

  const addEducation = () => {
    const _user = {
      ...realUser,
      educations: [...realUser.educations, { course: '', school: '', startYear: '', endYear: '' }]
    };

    dispatch(updateRealUser(_user));
  };

  const removeEducation = (key: number) => {
    const _user = {
      ...realUser,
      educations: realUser.educations.filter((education, index) => index !== key)
    };
    dispatch(updateRealUser(_user));
  };

  return (
    <VStack mt="10px" border="1px solid #e5e5e5" px="5px" py="5px" w="full">
      <AppText variant="formLabel" mb="10px" alignSelf="start">
        Academic Qualification
      </AppText>
      {realUser.educations.map((education, key) => {
        return (
          <VStack mt={6} key={key} w="full">
            <HStack w="full" justify="space-between">
              <Input
                className="school-name"
                placeholder="School"
                maxLength={30}
                variant="formInput"
                value={education.school}
                onChange={e => {
                  const _education = realUser.educations.map((edu, idx) => {
                    const ed = { ...edu };
                    if (key === idx) ed.school = e.target.value;
                    return ed;
                  });
                  onUserFormChange('educations', _education);
                }}
              />
              {/* <VStack align="right">
                <FormLabel>Start Year</FormLabel>
                <Input
                  placeholder="Start Year"
                  size="sm"
                  maxLength={4}
                  value={education.startYear}
                  onChange={e => {
                    const _education = realUser.educations.map((edu, idx) => {
                      const ed = { ...edu };
                      if (key === idx) ed.startYear = e.target.value;
                      return ed;
                    });
                    onUserFormChange('educations', _education);
                  }}
                />
              </VStack>
              <VStack align="right">
                <FormLabel>End Year</FormLabel>
                <Input
                  placeholder="End year"
                  size="sm"
                  maxLength={4}
                  value={education.endYear}
                  onChange={e => {
                    const _education = realUser.educations.map((edu, idx) => {
                      const ed = { ...edu };
                      if (key === idx) ed.endYear = e.target.value;
                      return ed;
                    });
                    onUserFormChange('educations', _education);
                  }}
                />
              </VStack> */}
              <Input
                className="course-name"
                placeholder="Course Name"
                variant="formInput"
                maxLength={50}
                value={education.course}
                onChange={e => {
                  const _education = realUser.educations.map((edu, idx) => {
                    const ed = { ...edu };
                    if (key === idx) ed.course = e.target.value;
                    return ed;
                  });
                  onUserFormChange('educations', _education);
                }}
              />
              <VStack>
                <FaTrash color="#808B96" onClick={() => removeEducation(key)} cursor="pointer" />
              </VStack>
            </HStack>
            <Box width={'100%'}>
              {/* <Textarea
                placeholder="More details"
                size="sm"
                height="6rem"
                value={education.moreDetails}
                onChange={e => {
                  const _education = realUser.educations.map((edu, idx) => {
                    const ed = { ...edu };
                    if (key === idx) ed.moreDetails = e.target.value;
                    return ed;
                  });
                  onUserFormChange('educations', _education);
                }}
              /> */}
            </Box>
            <Divider />
          </VStack>
        );
      })}

      <HStack w="full" mt={6} justify="end">
        <Button
          bg="transparent"
          color="#000"
          size="sm"
          onClick={addEducation}
          border="1px solid #e9e9e9"
          id="add-education"
        >
          <AppText variant="formLabel" fontWeight={500}>
            Add Education
          </AppText>
        </Button>
      </HStack>
    </VStack>
  );
};

export default EducationDetails;
