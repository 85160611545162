import { Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import WorkExperienceCard from './WorkExperienceCard';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';
import { updateRealUser } from 'state/cv/cvState';

interface Props {}

const WorkExperienceDetails: React.FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;
  const experiences = realUser.workExperience.length
    ? realUser.workExperience
    : defaultUser.workExperience;

  return (
    <Flex direction="column">
      <Flex direction="column">
        {experiences.map((experience, key) => {
          return <WorkExperienceCard experience={experience} key={key} marginTop="1em" />;
        })}
      </Flex>
    </Flex>
  );
};

export default WorkExperienceDetails;
