import { useCallback } from 'react';
import { Box, Button, Center, Flex, HStack, Image, VStack, useDisclosure } from '@chakra-ui/react';
import Logo from 'images/svg/fullLogo.svg';
import AppText from 'components/AppText';
import UserDetailCollectorForm from 'components/UserDetailCollectorForm/UserDetailCollectorForm';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ClassicCvHtml from 'components/Html/ClassicCv';
import ClassicProCvHtml from 'components/Html/ClassicProCv';
import { IRootState } from 'state/rootState';
import { useDispatch, useSelector } from 'react-redux';
import WinterCvHtml from 'components/Html/WinterCv';
import WinterProCvHtml from 'components/Html/WinterProCv';
import classicProCvProps from 'components/Html/ClassicProCv/properties';
import { setCvProps } from 'state/cv/cvState';
import AppButton from 'components/AppButton';
import { ContactUs } from 'utills/contact';
import DrawerContactUs from 'components/AppDrawer/AppDrawer';

ReactGA.initialize('G-WB6JY40D2Y');
ReactGA.send(window.location.pathname + window.location.search);

function App() {
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
  const navigate = useNavigate();
  const selectedCv = useSelector((state: IRootState) => state.cv.selectedCv);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cvHtmlSelector = useCallback(() => {
    switch (selectedCv) {
      case 'classicCv':
        return <ClassicCvHtml />;
      case 'classicProCv':
        dispatch(setCvProps(classicProCvProps));
        return <ClassicProCvHtml />;
      case 'winterCv':
        return <WinterCvHtml />;
      case 'winterProCv':
        return <WinterProCvHtml />;
    }
  }, [selectedCv]);

  if (isMobile) {
    return (
      <Center p={20} textAlign="center">
        <VStack>
          <Image
            src={Logo}
            width={[32, 32, 32, 32]}
            alt="logo"
            onClick={() => navigate('/')}
            cursor="pointer"
          />
          <AppText color="red" pt={10} fontSize={20}>
            Sorry, We do not support mobile devices to generate resumes
          </AppText>
        </VStack>
      </Center>
    );
  }

  return (
    <>
      <DrawerContactUs isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Button
        variant="primary"
        position="absolute"
        right={2}
        zIndex={1}
        bottom={2}
        onClick={onOpen}
      >
        Join us
      </Button>
      <HStack bg="#F0F0F0" w="100%" justify="center">
        <Box
          h="100vh"
          w="500px"
          overflowY="auto"
          bg="#ffffff"
          p={4}
          borderRadius={6}
          overflow="scroll"
          __css={{
            '&::-webkit-scrollbar': {
              w: '1'
            },
            '&::-webkit-scrollbar-track': {
              w: '1'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: `#e4e4e4`
            }
          }}
          overflowX="hidden"
        >
          <UserDetailCollectorForm />
        </Box>
        <Box
          h="90vh"
          w="700px"
          overflow="scroll"
          __css={{
            '&::-webkit-scrollbar': {
              w: '1'
            },
            '&::-webkit-scrollbar-track': {
              w: '1'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: `#4A154B`
            }
          }}
          overflowX="hidden"
        >
          {cvHtmlSelector()}
        </Box>
      </HStack>
    </>
  );
}

export default App;
