import jsPDF from 'jspdf';
import { User } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';
import {
  regularFontFamily,
  regularFontSize,
  subTitleFontFamily,
  subTitleFontSize
} from '../classicPDFproperty';

export const skills = (user: User, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  doc.setFont(subTitleFontFamily);
  doc.setFontSize(subTitleFontSize);
  doc.text('SKILLS', startX, nextLine, {
    align: 'left'
  });

  doc.setFont(regularFontFamily);
  doc.setFontSize(regularFontSize);

  nextLine += 25;

  user.skills.forEach(skill => {
    nextLine = addParagraphText(`\u2022 ${skill}`, 'left', startX, nextLine, 150, 16, 40, doc);
  });
  return nextLine;
};
