import { Box, Button, Divider, FormLabel, HStack, Input, Textarea, VStack } from '@chakra-ui/react';
import AppText from 'components/AppText';
import { FC } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateRealUser } from 'state/cv/cvState';
import { Experience, User } from 'types/User';

interface Props {
  onUserFormChange: (field: string, value: string | Experience[]) => void;
  realUser: User;
}

const WorkExperience: FC<Props> = ({ realUser, onUserFormChange }) => {
  const dispatch = useDispatch();
  const addWorkExperience = () => {
    const _user = {
      ...realUser,
      workExperience: [
        ...realUser.workExperience,
        { companyName: '', designation: '', startYear: '', endYear: '' }
      ]
    };
    dispatch(updateRealUser(_user));
  };

  const removeWorkExperience = (key: number) => {
    const _user = {
      ...realUser,
      workExperience: realUser.workExperience.filter((experience, index) => index !== key)
    };
    dispatch(updateRealUser(_user));
  };

  return (
    <VStack mt="10px" border="1px solid #e5e5e5" px="5px" py="5px">
      <AppText variant="formLabel" mb="10px" alignSelf="start">
        Work Experience
      </AppText>
      {realUser.workExperience.map((experience, key) => {
        return (
          <VStack mt={6} key={key} w="full">
            <HStack w="full" justify="space-between">
              <VStack align="right">
                <Input
                  variant="formInput"
                  className="designation"
                  placeholder="Designation"
                  value={experience.designation}
                  onChange={e => {
                    const _workExperience = realUser.workExperience.map((exp, idx) => {
                      const ex = { ...exp };
                      if (key === idx) ex.designation = e.target.value;
                      return ex;
                    });
                    onUserFormChange('workExperience', _workExperience);
                  }}
                />
              </VStack>
              <VStack align="right">
                <Input
                  variant="formInput"
                  className="start-year"
                  placeholder="Start Year"
                  size="sm"
                  maxLength={4}
                  value={experience.startYear}
                  onChange={e => {
                    const _workExperience = realUser.workExperience.map((exp, idx) => {
                      const ex = { ...exp };
                      if (key === idx) ex.startYear = e.target.value;
                      return ex;
                    });
                    onUserFormChange('workExperience', _workExperience);
                  }}
                />
              </VStack>
              <VStack align="right">
                <Input
                  variant="formInput"
                  className="end-year"
                  placeholder="End year"
                  size="sm"
                  maxLength={4}
                  value={experience.endYear}
                  onChange={e => {
                    const _workExperience = realUser.workExperience.map((exp, idx) => {
                      const ex = { ...exp };
                      if (key === idx) ex.endYear = e.target.value;
                      return ex;
                    });
                    onUserFormChange('workExperience', _workExperience);
                  }}
                />
              </VStack>
              <VStack>
                <FaTrash
                  color="#808B96"
                  onClick={() => removeWorkExperience(key)}
                  cursor="pointer"
                />
              </VStack>
            </HStack>
            <Textarea
              className="more-details"
              placeholder="More details"
              fontSize="10px"
              pl="5px"
              height="6rem"
              value={experience.moredetails}
              onChange={e => {
                const _workExperience = realUser.workExperience.map((exp, idx) => {
                  const ex = { ...exp };
                  if (key === idx) ex.moredetails = e.target.value;
                  return ex;
                });
                onUserFormChange('workExperience', _workExperience);
              }}
              borderRadius="2px"
              _focus={{
                border: '1px solid #c4c1c1',
                outline: 'none',
                boxShadow: 'none'
              }}
            />
            <Divider />
          </VStack>
        );
      })}

      <HStack justify="flex-end" mt={6} w="full">
        <Button
          bg="transparent"
          color="#000"
          size="sm"
          onClick={addWorkExperience}
          id="add-experience"
          border="1px solid #e9e9e9"
          alignSelf="flex-end"
        >
          <AppText variant="formLabel" fontWeight={500}>
            Add Experience
          </AppText>
        </Button>
      </HStack>
    </VStack>
  );
};

export default WorkExperience;
