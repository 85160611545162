import { BoxProps, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Experience } from "types/User";

interface Props extends BoxProps {
  experience: Experience;
}

const WorkExperience: React.FC<Props> = ({ experience, ...rest }) => {
  return (
    <Stack {...rest}>
      <HStack align="flex-start">
        <Text align="left" color="#555" >{experience.designation}</Text>
        <Text color="#555" >{experience.startYear}</Text>
        <Text color="#555" >to</Text>
        <Text color="#555" >{experience.endYear}</Text>
      </HStack>

      <Text textAlign="justify" color="#555">{experience.moredetails}</Text>
    </Stack>
  );
};

export default WorkExperience;
