import classicCv from 'images/png/cv-templates/classic-cv.png';
import classicProCv from 'images/png/cv-templates/classic-pro-cv.png';
import winterCv from 'images/png/cv-templates/winter-cv.png';
import winterProCv from 'images/png/cv-templates/winter-pro-cv.png';
import { cvType } from 'state/cv/cvState';

export interface cvListItem {
  id: cvType;
  displayName: string;
  image: string;
}

export const cvList: cvListItem[] = [
  {
    id: 'classicCv',
    displayName: 'Classic CV',
    image: classicCv
  },
  {
    id: 'classicProCv',
    displayName: 'Classic Pro CV',
    image: classicProCv
  },
  {
    id: 'winterCv',
    displayName: 'Winter',
    image: winterCv
  },
  {
    id: 'winterProCv',
    displayName: 'Winter Pro',
    image: winterProCv
  }
];
