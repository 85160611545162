import jsPDF from 'jspdf';
import { addParagraphText } from 'utills/jsPdf';

export const summary = (
  userSummary: string,
  doc: jsPDF,
  startX: number,
  startY: number,
  pageWidth: number
) => {
  // Define summary start index
  let nextLine = startY;

  doc.setFontSize(12);
  doc.text('ABOUT ME', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 25;

  // Add paragraph
  nextLine = addParagraphText(userSummary, 'justify', startX, nextLine, pageWidth, 15, 120, doc);

  return nextLine;
};
