import { Box, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  accomplishment: string;
}

const AccomplishmentCard: React.FC<Props> = ({ accomplishment, ...rest }) => {
  return (
    <Box color={'#ffffff'} {...rest}>
      <Text fontSize={'14px'}>{`\u2022 ${accomplishment}`}</Text>
    </Box>
  );
};

export default AccomplishmentCard;
