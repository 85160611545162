import { extendTheme } from '@chakra-ui/react';
import components from './components';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'Poppins'
      },
      a: {
        fontFamily: 'Poppins',
        fontWeight: '400'
      }
    }
  },
  components
});

export default theme;
