import jsPDF from 'jspdf';
import { Experience, User } from 'types/User';
import { experienceItem } from './experienceItem';

let nextLine = 0;

export const experience = (user: User, doc: jsPDF, startX: number, startY: number) => {
  nextLine = startY;

  doc.setFontSize(12);
  doc.text('EXPERIENCE', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 25;

  user.workExperience.forEach((exp: Experience) => {
    nextLine = experienceItem(exp, doc, startX, nextLine);
    nextLine += 20;
  });
  return nextLine;
};
