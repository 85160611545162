import { line } from 'components/Line/line';
import jsPDF from 'jspdf';
import { MontserratAlternates, Sora } from '../../../fonts';
import { Education, Experience, User } from '../../../types/User';
import { addParagraphText } from '../../../utills/jsPdf';
import { accomplishments } from './Accomplishments/accomplishments';
import { contact } from './contact';
import { education } from './Education/education';
import { experience } from './Experience/experience';
import { header } from './header';
import { reference } from './Reference/reference';
import { skills } from './Skills/skills';
import { summary } from './summary';

interface Props {
  user: User;
}

const winterCVpdf = ({ user }: Props) => {
  let doc = new jsPDF('p', 'pt', 'a4');

  let pageWidth = 595;
  let pageHeight = 842;
  let pageMargin = 30;

  pageWidth -= pageMargin * 2;
  pageHeight -= pageMargin * 2;

  let startX = pageMargin;
  let startY = 20;
  let nextLine = startY;
  let headerEndHeight = 0;
  let summaryEndHeight = 0;
  let skillEndHeight = 0;
  let achievementsEndHeight = '';
  let educationEndHeight = '';
  let contactEndHeight = 0;
  let fullPageWidth = startX * 2 + pageWidth;
  const sectionGap = 20;

  doc.addFileToVFS('MontserratAlternates.ttf', MontserratAlternates);
  doc.addFont('MontserratAlternates.ttf', 'MontserratAlternates', 'normal');
  doc.addFileToVFS('Sora.ttf', Sora);
  doc.addFont('Sora.ttf', 'Sora', 'normal');
  doc.setFont('Sora');
  doc.setTextColor('#fff');

  // line({
  //   doc,
  //   startPoint: { x: 0, y: 0 },
  //   length: { w: fullPageWidth, h: 5 },
  //   color: 'green'
  // });

  line({
    doc,
    startPoint: { x: 0, y: 0 },
    length: { w: fullPageWidth, h: 100 },
    color: '#880000'
  });

  headerEndHeight = 110;

  headerEndHeight = header(user, doc, startX, nextLine, fullPageWidth) + sectionGap;
  headerEndHeight = 120;

  doc.setTextColor('#555');

  // Summary section
  summaryEndHeight = summary(user.summary, doc, startX, headerEndHeight + 10, 300) + sectionGap;

  nextLine = experience(user, doc, startX, summaryEndHeight);

  nextLine += 20;

  const skillsStartY = nextLine;
  nextLine = skills(user, doc, startX + pageWidth - 150, headerEndHeight + 10);

  // // const educationStartY = headerEndHeight;
  // nextLine = education(user, doc, startX + pageWidth - 150, headerEndHeight + 65);

  // nextLine += 20;

  nextLine += 20;

  const accomplishmentStartY = nextLine;
  nextLine = accomplishments(user, doc, startX + pageWidth - 150, accomplishmentStartY);

  nextLine += 20;

  nextLine = reference(user, doc, startX + pageWidth - 150, nextLine);

  doc.save(`${user.firstName}-winter-cv.pdf`);
};

export default winterCVpdf;
