import jsPDF from 'jspdf';
import { Education, User } from 'types/User';
import { educationItem } from './educationItem';
import {
  regularFontFamily,
  regularFontSize,
  subTitleFontFamily,
  subTitleFontSize
} from '../classicPDFproperty';

let nextLine = 0;

export const education = (user: User, doc: jsPDF, startX: number, startY: number) => {
  nextLine = startY;

  doc.setFont(subTitleFontFamily);
  doc.setFontSize(subTitleFontSize);
  doc.text('EDUCATION', startX, nextLine, {
    align: 'left'
  });

  doc.setFont(regularFontFamily);
  doc.setFontSize(regularFontSize);

  nextLine += 25;

  user.educations.forEach((exp: Education) => {
    nextLine = educationItem(exp, doc, startX, nextLine);
  });
  return nextLine;
};
