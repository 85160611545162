import { Box, Button, Center, Divider, Flex, Image, VStack } from '@chakra-ui/react';
import AppButton from 'components/AppButton';
import AppText from 'components/AppText';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeTemplate, cvType } from 'state/cv/cvState';
import { cvList } from 'utills/cv';

type Props = {};

const routeVariants = {
  initial: {
    x: '200vh'
  },
  animate: {
    x: '0vh',
    transition: {
      type: 'spring',
      mass: 0.2
    }
  },
  exit: {
    x: '-200vh',

    transition: {
      type: 'spring',
      mass: 0.2
    }
  }
};

export default function CVTemplates({}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onTemplateImageClick = (cvType: cvType) => {
    dispatch(changeTemplate(cvType));
    navigate('/cv-maker-online');
  };

  return (
    <Box
      as={motion.div}
      variants={routeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      bg="rgb(180, 244, 255)"
      bgGradient="radial-gradient(circle, rgba(180, 244, 255, 1) 0%, rgba(0, 185, 255, 1) 100%)"
    >
      <VStack>
        <Box p="20px">
          <AppButton
            fontFamily="Lumanosimo"
            variant="outline"
            onClick={() => navigate('/')}
            width="200px"
            height="50px"
            borderRadius={20}
            color="#fff"
          >
            Back
          </AppButton>
        </Box>
        <Box h={['auto', 'calc(100vh - 98px)']}>
          <Flex wrap="wrap" gap={5} direction={['column', 'row']} pt={[5, 0]}>
            {cvList.map(cv => (
              <VStack bg="#fff" px="15px" py="10px" borderRadius={5}>
                <AppText size={'sm'} variant={'normal'} color="#000">
                  {cv.displayName}
                </AppText>
                <Image
                  key={cv.id}
                  src={cv.image}
                  width={[260, 250, 250, 250]}
                  alt={cv.displayName}
                  cursor="pointer"
                  onClick={() => onTemplateImageClick(cv.id as cvType)}
                />
              </VStack>
            ))}
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
}
