import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  accomplishment: string;
}

const AccomplishmentCard: React.FC<Props> = ({ accomplishment, ...rest }) => {
  return <Box {...rest}>{`\u2022 ${accomplishment}`}</Box>;
};

export default AccomplishmentCard;
