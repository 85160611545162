export const TextStyles = {
  baseStyle: {
    borderRadius: 'sm',
    color: '#ffffff',
    _focus: {},
    _hover: {}
  },
  sizes: {
    lsm: {
      fontSize: '12px'
    },
    sm: {
      fontSize: '15px'
    },
    xs: {
      fontSize: '20px'
    },
    md: {
      fontSize: '30px'
    },
    lg: {
      fontSize: '40px'
    },
    xl: {
      fontSize: '50px'
    }
  },
  variants: {
    mainTitle: {
      color: '#4A154B',
      fontWeight: 'bold'
    },
    title: {
      color: '#ffffff',
      fontWeight: 'bold'
    },
    normal: {
      color: '#000000'
    },
    regular: {
      color: '#767E8E'
    },
    label: {
      color: '#454545'
    },
    formLabel: {
      color: '#000',
      fontSize: '12px',
      fontWeight: 600
    }
  }
};
