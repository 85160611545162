import { HStack, Input, Textarea, VStack, border } from '@chakra-ui/react';
import AppText from 'components/AppText';
import { FC } from 'react';
import { User } from 'types/User';

interface Props {
  onUserFormChange: (field: string, value: string) => void;
  realUser: User;
}

const PersonalInfo: FC<Props> = ({ realUser, onUserFormChange }) => {
  return (
    <VStack border="1px solid #e5e5e5" px="5px" py="5px">
      <AppText variant="formLabel" mb="10px" alignSelf="start">
        Personal Details
      </AppText>
      <HStack w="full">
        <Input
          variant="formInput"
          type="text"
          placeholder="First name"
          required
          onChange={e => onUserFormChange('firstName', e.target.value)}
          value={realUser.firstName}
          onFocus={e => e.target.select()}
          id="first-name"
        />
        <Input
          variant="formInput"
          type="text"
          placeholder="Last name"
          required
          onChange={e => onUserFormChange('lastName', e.target.value)}
          value={realUser.lastName}
          onFocus={e => e.target.select()}
          id="last-name"
        />
      </HStack>
      <HStack w="full">
        <Input
          variant="formInput"
          type={'text'}
          placeholder="Email"
          required
          onChange={e => onUserFormChange('email', e.target.value)}
          value={realUser.email}
          onFocus={e => e.target.select()}
          id="email"
        />
        <Input
          variant="formInput"
          type={'text'}
          placeholder="Phone Number"
          required
          onChange={e => onUserFormChange('phone', e.target.value)}
          value={realUser.phone}
          onFocus={e => e.target.select()}
          id="phone-number"
        />
      </HStack>
      <Textarea
        w="full"
        placeholder="Summary"
        height="6rem"
        fontSize="10px"
        pl="5px"
        fontWeight={300}
        onChange={e => onUserFormChange('summary', e.target.value)}
        value={realUser.summary}
        onFocus={e => e.target.select()}
        id="summary"
        borderRadius="2px"
        _focus={{
          border: '1px solid #c4c1c1',
          outline: 'none',
          boxShadow: 'none'
        }}
      />
    </VStack>
  );
};

export default PersonalInfo;
