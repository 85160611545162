import jsPDF from 'jspdf';
import { User } from 'types/User';

export const contact = (
  user: User,
  doc: jsPDF,
  startX: number,
  startY: number,
  maxWidth: number
) => {
  // Define header start index
  let nextLine = startY;

  doc.setFontSize(12);
  doc.text('CONTACT', startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 25;

  // Set the email address
  doc.text(user.email, startX, nextLine, {
    align: 'left'
  });

  nextLine += 20;

  // Set the user contact number
  doc.text(user.phone, startX, nextLine, {
    align: 'left'
  });

  nextLine += 20;
  // Set the user address
  doc.text(user.address, startX, nextLine, {
    align: 'left',
    maxWidth
  });

  nextLine += 20;

  return nextLine;
};
