import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import AccomplishmentCard from './AccomplishmentCard';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {}

const AccomplishmentsDetails: React.FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;
  const accomplishments = realUser.accomplishments.length
    ? realUser.accomplishments
    : defaultUser.accomplishments;
  return (
    <Flex direction="column" paddingTop={30}>
      <Text textAlign="left" fontSize="16px">
        Accomplishments
      </Text>
      <Flex direction="column">
        {accomplishments.map((accomplishment, key) => {
          return (
            <AccomplishmentCard
              accomplishment={accomplishment}
              key={key}
              marginTop="1em"
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default AccomplishmentsDetails;
