import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { HStack, Input, Textarea, VStack } from '@chakra-ui/react';
import AppText from 'components/AppText';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactUs = () => {
  const form = useRef<any>();
  const notify = () => toast('Your message has been sent successfully, We will contact you soon');

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm('service_g0ygm5n', 'futurejobs_mo3ozim', form.current, 'PBRVP9C6whZ7t-4ti')
      .then(
        result => {
          form.current.reset();
          notify();
        },
        error => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <form ref={form} onSubmit={sendEmail}>
        <VStack border="1px solid #e5e5e5" px="5px" py="5px">
          <Input variant="formInput" type="text" placeholder="Name" required name="user_name" />
          <Input variant="formInput" type="email" placeholder="Email" required name="user_email" />
          <Textarea
            w="full"
            placeholder="Summary"
            height="6rem"
            fontSize="10px"
            pl="5px"
            fontWeight={300}
            name="message"
            borderRadius="2px"
            _focus={{
              border: '1px solid #c4c1c1',
              outline: 'none',
              boxShadow: 'none'
            }}
          />
          <Input
            type="submit"
            value="Send Message"
            cursor="pointer"
            bg="transparent"
            fontSize="12px"
            color="#000"
            _hover={{
              bg: '#4A154B',
              color: '#fff'
            }}
          />
        </VStack>
      </form>
      <AppText fontSize="13px" textAlign="center" fontWeight={300} color="#4A154B" mt={10}>
        We are actively looking for the business partners who can work with us
      </AppText>
    </>
  );
};
