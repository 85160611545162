import { ButtonStyles as Button } from './ButtonStyles';
import { TextStyles as Text } from './TextStyles';
import { InputStyles as Input } from './InputStyles';

const components = {
  Button,
  Text,
  Input
};

export default components;
