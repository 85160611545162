import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import SkillCard from './SkillCard';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {}

const SkillsDetails: React.FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;
  const skills = realUser.skills.length ? realUser.skills : defaultUser.skills;
  return (
    <Flex direction="column">
      <Text textAlign="left" fontSize="16px">
        Skills
      </Text>
      <Flex direction="column">
        {skills.map((skill, key) => {
          return (
            <SkillCard
              skill={skill}
              key={key}
              color={'#ffffff'}
              marginTop="1em"
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default SkillsDetails;
