import { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import WorkExperienceDetails from './WorkExperience/WorkExperienceDetails';
import SkillsDetails from './Skills/SkillsDetails';
import AccomplishmentsDetails from './Accomplishments/AccomplishmentsDetails';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';
import { winterProCVpdf } from 'components/Pdf';

interface Props {}

const WinterProCvHtml: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;

  const exportPDF = async () => {
    winterProCVpdf({ user: realUser });
  };

  return (
    <>
      <HStack justify="end">
        <Button onClick={exportPDF} colorScheme="teal" size="sm">
          Winter Pro CV Download
        </Button>
      </HStack>
      <Flex>
        <Box
          flex={2}
          paddingLeft="2rem"
          paddingRight="2rem"
          bg={'#ffffff'}
          pt={6}
        >
          <HStack>
            <Box pl={10}>
              <Image
                borderRadius="full"
                boxSize="100px"
                src="https://bit.ly/dan-abramov"
                alt="Dan Abramov"
              />
            </Box>
            <Box>
              <Heading as="h2" fontSize={22} fontWeight={400}>
                {realUser.firstName !== ''
                  ? realUser.firstName + ' ' + realUser.lastName
                  : defaultUser.firstName + ' ' + defaultUser.lastName}
              </Heading>

              <Heading as="h2" fontSize={15} fontWeight={400} pt={2}>
                {realUser.jobTitle !== ''
                  ? realUser.jobTitle
                  : defaultUser.jobTitle}
              </Heading>
            </Box>
          </HStack>

          <Heading as="h2" fontSize={15} fontWeight={400} pt={8}>
            ABOUT ME
          </Heading>
          <Text as="h2" textAlign="justify" paddingTop="1rem" color="#555">
            {realUser.summary !== '' ? realUser.summary : defaultUser.summary}
          </Text>
          <WorkExperienceDetails />
        </Box>
        <Box flex={1.5} paddingLeft="2rem" bg={'#070268'} pt={8}>
          <SkillsDetails />

          <AccomplishmentsDetails />
        </Box>
      </Flex>
    </>
  );
};

export default WinterProCvHtml;
