import jsPDF from 'jspdf';
import { User } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';
import {
  regularFontFamily,
  regularFontSize,
  subTitleFontFamily,
  subTitleFontSize
} from '../classicPDFproperty';

export const accomplishments = (user: User, doc: jsPDF, startX: number, startY: number) => {
  let nextLine = startY;

  doc.setFont(subTitleFontFamily);
  doc.setFontSize(subTitleFontSize);
  doc.text('ACCOMPLISHMENTS', startX, nextLine, {
    align: 'left'
  });

  doc.setFont(regularFontFamily);
  doc.setFontSize(regularFontSize);

  nextLine += 25;

  user.accomplishments.forEach(accomplishment => {
    nextLine = addParagraphText(
      `\u2022 ${accomplishment}`,
      'left',
      startX,
      nextLine,
      150,
      16,
      40,
      doc
    );
  });

  return nextLine;
};
