import { FC, useContext } from 'react';
import EducationCard from './EducationCard';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {}

const EducationDetails: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;
  const educations = realUser.educations.length ? realUser.educations : defaultUser.educations;

  return (
    <Flex direction="column">
      <Flex direction="column">
        {educations.map((education, key) => {
          return <EducationCard education={education} key={key} marginTop="1em" />;
        })}
      </Flex>
    </Flex>
  );
};

export default EducationDetails;
