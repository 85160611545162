import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';
import { User } from 'types/User';

export const header = async (
  user: User,
  doc: jsPDF,
  startX: number,
  startY: number,
  maxWidth: number,
  imageUrl: string
) => {
  const promise: Promise<number> = new Promise((resolve, reject) => {
    let nextLine = startY;

    const img = new Image();
    img.src = imageUrl;
    img.onload = async () => {
      await doc.addImage(img, 'png', startX + 20, nextLine, 100, 100);

      nextLine += 125;

      doc.setFontSize(15);
      doc.text(user.firstName + ' ' + user.lastName, startX, nextLine, { align: 'left', maxWidth });
      doc.setFontSize(8);

      nextLine += 20;

      doc.setFontSize(9);
      doc.text(user.jobTitle, startX, nextLine, { align: 'left', maxWidth });
      doc.setFontSize(8);

      nextLine += 20;

      resolve(nextLine);
    };
  });
  return promise.then(nl => nl);
};
