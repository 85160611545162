import { FC } from 'react';
import Personalnfo from './Personalnfo';
import EducationDetails from './Educations/EducationDetails';
import { Box, Button, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import WorkExperienceDetails from './WorkExperience/WorkExperienceDetails';
import SkillsDetails from './Skills/SkillsDetails';
import AccomplishmentsDetails from './Accomplishments/AccomplishmentsDetails';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';
import { classicCVpdf } from 'components/Pdf';
import { FaFileDownload } from 'react-icons/fa';
import AppText from 'components/AppText';

interface Props {}

const ClassicCvHtml: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;

  const exportPDF = async () => {
    classicCVpdf({ user: realUser });
  };

  return (
    <>
      <HStack justify="end">
        <Button onClick={exportPDF}>
          <HStack spacing="5px">
            <FaFileDownload color="#555" />{' '}
            <AppText variant="formLabel" mb="10px" alignSelf="start">
              Download
            </AppText>
          </HStack>
        </Button>
      </HStack>
      <Box fontSize="12px" bg="#fff" color="#555">
        <Box width="100%" border="1px solid #edeaea" pb={2}>
          <Personalnfo />
          <Flex paddingLeft="2rem">
            <Box flex={5} paddingRight="2rem">
              <Heading as="h2" fontSize={15} fontWeight={600} pt={8}>
                ABOUT ME
              </Heading>
              <Text as="h2" size="3xl" textAlign="justify" paddingTop="1rem" color="#555">
                {realUser.summary !== '' ? realUser.summary : defaultUser.summary}
              </Text>
              <Heading as="h2" fontSize={15} fontWeight={600} pt={8}>
                WORK EXPERIENCE
              </Heading>
              <WorkExperienceDetails />
            </Box>
            <Box flex={2}>
              <Heading as="h2" fontSize={15} fontWeight={600} pt={8}>
                EDUCATION
              </Heading>
              <EducationDetails />
              <Heading as="h2" fontSize={15} fontWeight={600} pt={8}>
                SKILLS
              </Heading>
              <SkillsDetails />
              <Heading as="h2" fontSize={15} fontWeight={600} pt={8}>
                ACCOMPLISHMENT
              </Heading>
              <AccomplishmentsDetails />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ClassicCvHtml;
