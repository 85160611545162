import jsPDF from 'jspdf';

export const addLabelText = (
  text: string,
  startX: number,
  startY: number,
  align: 'center' | 'right' | 'left' | 'justify',
  fontStyle: string,
  doc: jsPDF
) => {
  doc.setFont('', fontStyle).text(text, startX, startY, {
    align: align,
    horizontalScale: 1,
    lineHeightFactor: 1.5
  });
};

export const addParagraphText = (
  text: string,
  align: 'center' | 'right' | 'left' | 'justify',
  startX: number,
  startY: number,
  maxWidth: number,
  lineHeight: number,
  charPerLine: number,
  doc: jsPDF
) => {
  if (align === 'justify') {
    return justifiedText(doc, text, startX, startY, maxWidth);
  } else {
    doc.text(text, startX, startY, {
      align: align,
      maxWidth: maxWidth,
      horizontalScale: 1,
      lineHeightFactor: 1.5
    });
    const numOfLines = Math.ceil(text.length / charPerLine);
    let contentHeight = numOfLines * lineHeight;
    if (numOfLines < 4) {
      contentHeight += 8;
    }
    const nextLine = contentHeight + startY;
    return nextLine;
  }
};

export function justifiedText(
  pdfGen: jsPDF,
  text: string,
  xStart: number,
  yStart: number,
  textWidth: number
) {
  text = text.replace(/(?:\r\n|\r|\n)/g, ' ');
  text = text.replace(/ +(?= )/g, '');
  const lineHeight = pdfGen.getTextDimensions('a').h * 1.5;
  const words = text.split(' ');
  let lineNumber = 0;
  let wordsInfo: IWordInfo[] = [];
  let lineLength = 0;
  for (const word of words) {
    const wordLength = pdfGen.getTextWidth(word + ' ');
    if (wordLength + lineLength > textWidth) {
      const y = yStart + lineNumber * lineHeight;
      if (y > 782) {
        pdfGen.addPage();
        lineNumber = 0;
        yStart = 50;
      }
      writeLine(pdfGen, wordsInfo, lineLength, lineNumber, xStart, yStart, lineHeight, textWidth);
      lineNumber += 1;
      wordsInfo = [];
      lineLength = 0;
    }
    wordsInfo.push({ text: word, wordLength });
    lineLength += wordLength;
  }
  if (wordsInfo.length > 0) {
    writeLastLine(wordsInfo, pdfGen, xStart, yStart, lineNumber, lineHeight);
  }

  return yStart + lineNumber * lineHeight + lineHeight;
}
function writeLastLine(
  wordsInfo: IWordInfo[],
  pdfGen: jsPDF,
  xStart: number,
  yStart: number,
  lineNumber: number,
  lineHeight: number
) {
  const line = wordsInfo.map(x => x.text).join(' ');
  pdfGen.text(line, xStart, yStart + lineNumber * lineHeight);
}

function writeLine(
  pdfGen: jsPDF,
  wordsInfo: IWordInfo[],
  lineLength: number,
  lineNumber: number,
  xStart: number,
  yStart: number,
  lineHeight: number,
  textWidth: number
) {
  const wordSpacing = (textWidth - lineLength) / (wordsInfo.length - 1);
  let x = xStart;
  const y = yStart + lineNumber * lineHeight;
  for (const wordInfo of wordsInfo) {
    pdfGen.text(wordInfo.text, x, y);
    x += wordInfo.wordLength + wordSpacing;
  }
}

interface IWordInfo {
  text: string;
  wordLength: number;
}
