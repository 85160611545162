import jsPDF from 'jspdf';
import { User } from 'types/User';

export const header = (
  user: User,
  doc: jsPDF,
  startX: number,
  startY: number,
  maxWidth: number
) => {
  // Define header start index
  let nextLine = startY;

  var img = new Image();
  img.src =
    'https://i2.wp.com/buddymantra.com/wp-content/uploads/2018/04/user-icon-png-pnglogocom.png?fit=500%2C466&ssl=1';

  doc.addImage(img, 'png', startX + 20, nextLine, 50, 50);

  nextLine += 25;

  doc.setFontSize(20);
  doc.text(user.firstName + ' ' + user.lastName, startX + 80, nextLine, {
    align: 'left',
    maxWidth
  });
  doc.setFontSize(16);

  nextLine += 15;

  doc.setFontSize(9);
  doc.text(user.jobTitle, startX + 80, nextLine, { align: 'left', maxWidth });
  doc.setFontSize(8);

  nextLine += 20;
  return nextLine;
};
