import App from 'App';
import CVTemplates from 'pages/Templates';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom';

function ErrorBoundary() {
  return <h1>Page not found 404!</h1>;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<App />} />
      <Route path="cv-templates" element={<CVTemplates />} />
      <Route path="/cv-maker-online" element={<App />} />
      <Route path="*" element={<ErrorBoundary />} />
    </Route>
  )
);

export default router;