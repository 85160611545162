import { Slice, createSlice } from '@reduxjs/toolkit';
import { User } from 'types/User';
import { defaultUser, realUser } from 'utills/user';

export type cvType = 'classicCv' | 'classicProCv' | 'winterCv' | 'winterProCv';
export interface ICv {
  defaultUser: User;
  realUser: User;
  primaryColor: string;
  isLoading: boolean;
  selectedCv: cvType;
  userImageUrl: string;
  cvProps: {
    hasImage: boolean;
  };
}

export const CvSlice = createSlice({
  name: 'cv',
  initialState: {
    defaultUser: defaultUser,
    realUser: realUser,
    primaryColor: 'green',
    selectedCv: 'classicCv',
    isLoading: false,
    userImageUrl: '',
    cvProps: {
      hasImage: false
    }
  } as ICv,
  reducers: {
    updateRealUser: (state, action) => {
      state.realUser = action.payload;
    },
    updateUserImageUrl: (state, action) => {
      state.userImageUrl = action.payload;
    },
    changeTemplate: (
      state,
      action: {
        payload: cvType;
        type: string;
      }
    ) => {
      state.selectedCv = action.payload;
    },
    setCvProps: (state, action) => {
      state.cvProps = action.payload;
    }
  }
});

export const { updateRealUser, changeTemplate, updateUserImageUrl, setCvProps } = CvSlice.actions;
export const cvReducer = CvSlice.reducer;
