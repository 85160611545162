import { line } from 'components/Line/line';
import jsPDF from 'jspdf';
import '../fonts/Kanit-SemiBold-bold';
import '../fonts/RobotoSlab-SemiBold';
import '../fonts/Kablammo-Regular-normal';
import '../fonts/Caprasimo-Regular-normal';
import '../fonts/Inter-Regular-normal';

import { MontserratAlternates, Sora } from '../../../fonts';
import { Education, Experience, User } from '../../../types/User';
import { addParagraphText } from '../../../utills/jsPdf';
import { accomplishments } from './Accomplishments/accomplishments';
import { education } from './Education/education';
import { experience } from './Experience/experience';
import { header } from './header';
import { reference } from './Reference/reference';
import { skills } from './Skills/skills';
import { summary } from './summary';

interface Props {
  user: User;
}

const classicCVpdf = ({ user }: Props) => {
  let doc = new jsPDF('p', 'pt', 'a4');

  let pageWidth = 595;
  let pageHeight = 842;
  let pageMargin = 50;

  pageWidth -= pageMargin * 2;
  pageHeight -= pageMargin * 2;

  let startX = pageMargin;
  let startY = pageMargin;
  let nextLine = startY;
  let headerEndHeight = 0;
  let summaryEndHeight = 0;
  let fullPageWidth = startX * 2 + pageWidth;

  doc.setTextColor('#555');

  line({
    doc,
    startPoint: { x: 0, y: 0 },
    length: { w: fullPageWidth, h: 5 },
    color: '#009DE6'
  });

  headerEndHeight = header(user, doc, startX, nextLine, pageWidth);

  line({
    doc,
    startPoint: { x: startX, y: headerEndHeight + 30 },
    length: { w: pageWidth, h: 1 },
    color: '#555'
  });

  summaryEndHeight = summary(user.summary, doc, startX, headerEndHeight + 65, 300);

  experience(user, doc, startX, summaryEndHeight);

  doc.setPage(1);

  nextLine = education(user, doc, startX + pageWidth - 150, headerEndHeight + 65);

  nextLine += 20;

  const skillsStartY = nextLine;
  nextLine = skills(user, doc, startX + pageWidth - 150, skillsStartY);

  nextLine += 20;

  const accomplishmentStartY = nextLine;
  nextLine = accomplishments(user, doc, startX + pageWidth - 150, accomplishmentStartY);

  // nextLine += 20;

  // nextLine = reference(user, doc, startX + pageWidth - 150, nextLine);

  doc.save(`${user.firstName}-classical-cv.pdf`);
};

export default classicCVpdf;
