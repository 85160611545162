import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CVTemplates from 'pages/Templates';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import theme from 'theme';
import MainLanding from 'pages/MainLanding';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { rootReducer } from 'state/rootState';
import App from 'App';
import Blog from 'pages/Blog';
import CoverLetter from 'pages/CoverLetters';
import { motion, AnimatePresence } from 'framer-motion';
import router from 'routes';

export const store = configureStore({
  reducer: rootReducer
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <MainLanding />
//   },
//   {
//     path: '/cv-templates',
//     element: <CVTemplates />
//   },
//   {
//     path: '/cover-letters',
//     element: <CoverLetter />
//   },
//   {
//     path: '/cv-maker-online',
//     element: <App />
//   },
//   {
//     path: '/blog',
//     element: <Blog />
//   }
// ]);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AnimatePresence>
          <RouterProvider router={router} />
        </AnimatePresence>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
