import jsPDF from 'jspdf';

interface Props {
  doc: jsPDF;
  startPoint: { x: number; y: number };
  length: { w: number; h: number };
  color: string;
}

export const line = ({ doc, startPoint, length, color }: Props) => {
  doc.setFillColor(color);
  doc.rect(startPoint.x, startPoint.y, length.w, length.h, 'F');
};
