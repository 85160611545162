import { Box, BoxProps, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { FC, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Education } from 'types/User';

interface Props extends BoxProps {
  education: Education;
}

const EducationCard: FC<Props> = ({ education, ...rest }) => {
  return (
    <Stack {...rest}>
      <HStack align="flex-start">
        <Text align="left" color="#555">
          {education.school}
        </Text>
      </HStack>

      <Text color="#555" fontSize={12} fontWeight={500}>
        {education.course}
      </Text>
    </Stack>
  );
};

export default EducationCard;
