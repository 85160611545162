import { FC } from 'react';
import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {}

const Personalnfo: FC<Props> = ({}) => {
  const cv = useSelector((state: IRootState) => state.cv);
  const realUser = cv.realUser;
  const defaultUser = cv.defaultUser;

  return (
    <VStack align="center" spacing=".5rem">
      <Flex flex={1} width="100%" w="100%" p={4} pl="2rem" pr="2rem">
        <Box flex={1} width="100%">
          <Text fontSize={30} fontWeight={800} noOfLines={1} textAlign="left" color="#555">
            {realUser.firstName !== ''
              ? realUser.firstName + ' ' + realUser.lastName
              : defaultUser.firstName + ' ' + defaultUser.lastName}
          </Text>
          <Text fontSize={13} noOfLines={1} textAlign="left" color="#555">
            {realUser.jobTitle !== '' ? realUser.jobTitle : defaultUser.jobTitle}
          </Text>
        </Box>
        <Box flex={1}>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.email !== '' ? realUser.email : defaultUser.email}
          </Text>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.phone !== '' ? realUser.phone : defaultUser.phone}
          </Text>
          <Text noOfLines={1} textAlign="right" color="#555">
            {realUser.address !== '' ? realUser.address : defaultUser.address}
          </Text>
        </Box>
      </Flex>
      <Flex w="100%" pl="2rem" pr="2rem">
        <Divider orientation="horizontal" bg="#555" height={0.3} borderWidth={0} />
      </Flex>
    </VStack>
  );
};

export default Personalnfo;
