import { line } from 'components/Line/line';
import jsPDF from 'jspdf';
import { MontserratAlternates, Sora } from '../../../fonts';
import { Education, Experience, User } from '../../../types/User';
import { addParagraphText } from '../../../utills/jsPdf';
import { accomplishments } from './Accomplishments/accomplishments';
import { contact } from './contact';
import { education } from './Education/education';
import { experience } from './Experience/experience';
import { header } from './header';
import { reference } from './Reference/reference';
import { skills } from './Skills/skills';
import { summary } from './summary';
import { useSelector } from 'react-redux';
import { IRootState } from 'state/rootState';

interface Props {
  user: User;
  imageUrl: string;
}

const classicProCVpdf = async ({ user, imageUrl }: Props) => {
  let doc = new jsPDF('p', 'pt', 'a4');

  let pageWidth = 595;
  let pageHeight = 842;
  let pageMargin = 30;

  pageWidth -= pageMargin * 2;
  pageHeight -= pageMargin * 2;

  let startX = pageMargin;
  let startY = 20;
  let nextLine = startY;
  let headerEndHeight = 0;
  let summaryEndHeight = 0;
  let skillEndHeight = 0;
  let achievementsEndHeight = '';
  let educationEndHeight = '';
  let contactEndHeight = 0;
  let fullPageWidth = startX * 2 + pageWidth;
  const sectionGap = 20;

  doc.addFileToVFS('MontserratAlternates.ttf', MontserratAlternates);
  doc.addFont('MontserratAlternates.ttf', 'MontserratAlternates', 'normal');
  doc.addFileToVFS('Sora.ttf', Sora);
  doc.addFont('Sora.ttf', 'Sora', 'normal');
  doc.setFont('Sora');
  doc.setTextColor('#fff');

  line({
    doc,
    startPoint: { x: 0, y: 0 },
    length: { w: 200, h: 842 },
    color: '#000'
  });

  // Summary section
  headerEndHeight = await header(user, doc, startX, nextLine, 140, imageUrl) + sectionGap;
  summaryEndHeight = summary(user.summary, doc, startX, headerEndHeight, 140) + sectionGap;
  contactEndHeight = contact(user, doc, startX, summaryEndHeight, 140) + sectionGap;
  skillEndHeight = skills(user, doc, startX, contactEndHeight, 140) + sectionGap;

  const rightPanelStartX = startX + 200;
  const rightPanelStartY = 40;
  nextLine = experience(user, doc, rightPanelStartX, rightPanelStartY);

  const educationStartY = nextLine;
  nextLine = education(user, doc, rightPanelStartX, educationStartY);

  nextLine += sectionGap;
  nextLine = reference(user, doc, rightPanelStartX, nextLine);

  doc.save(`${user.firstName}-classical-pro-cv.pdf`);
};

export default classicProCVpdf;
