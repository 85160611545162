import { Button, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import AppText from 'components/AppText';
import { FC } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateRealUser } from 'state/cv/cvState';
import { User } from 'types/User';

interface Props {
  onUserFormChange: (field: string, value: any) => void;
  realUser: User;
}

const SkillsAndAccomplishment: FC<Props> = ({ realUser, onUserFormChange }) => {
  const dispatch = useDispatch();

  const addSkills = () => {
    const _user = { ...realUser, skills: [...realUser.skills, ''] };
    dispatch(updateRealUser(_user));
  };
  const removeSkills = (key: number) => {
    const _user = {
      ...realUser,
      skills: realUser.skills.filter((skill, index) => index !== key)
    };
    dispatch(updateRealUser(_user));
  };
  const addAccomplishment = () => {
    const _user = { ...realUser, accomplishments: [...realUser.accomplishments, ''] };
    dispatch(updateRealUser(_user));
  };
  const removeAccomplishment = (key: number) => {
    const _user = {
      ...realUser,
      accomplishments: realUser.accomplishments.filter((experience, index) => index !== key)
    };
    dispatch(updateRealUser(_user));
  };

  return (
    <VStack mt="10px" border="1px solid #e5e5e5" px="5px" py="5px" w="full">
      <AppText variant="formLabel" mb="10px" alignSelf="start">
        Skills & Achievements
      </AppText>
      <HStack>
        <VStack>
          {realUser.skills.map((skill, key) => {
            return (
              <HStack>
                <Input
                  variant="formInput"
                  key={key}
                  value={skill}
                  onChange={e => {
                    const _skill = realUser.skills.map((skill, idx) => {
                      if (key === idx) skill = e.target.value;
                      return skill;
                    });
                    onUserFormChange('skills', _skill);
                  }}
                />
                <FaTrash color="#808B96" onClick={() => removeSkills(key)} cursor="pointer" />
              </HStack>
            );
          })}
          <HStack mt={6}>
            <Button
              bg="transparent"
              color="#000"
              border="1px solid #e9e9e9"
              size={'xs'}
              cursor="pointer"
              onClick={addSkills}
            >
              <AppText variant="formLabel" fontWeight={500}>
                Add Skill
              </AppText>
            </Button>
          </HStack>
        </VStack>
        <VStack>
          {realUser.accomplishments.map((accomplishment, key) => {
            return (
              <HStack>
                <Input
                  variant="formInput"
                  key={key}
                  value={accomplishment}
                  onChange={e => {
                    const _accomplishment = realUser.accomplishments.map((accomplishment, idx) => {
                      if (key === idx) accomplishment = e.target.value;
                      return accomplishment;
                    });
                    onUserFormChange('accomplishments', _accomplishment);
                  }}
                />
                <FaTrash
                  color="#808B96"
                  onClick={() => removeAccomplishment(key)}
                  cursor="pointer"
                />
              </HStack>
            );
          })}
          <HStack mt={6}>
            <Button
              bg="transparent"
              border="1px solid #e9e9e9"
              color="#000"
              size={'xs'}
              cursor="pointer"
              onClick={addAccomplishment}
            >
              <AppText variant="formLabel" fontWeight={500}>
                Add Accomplishment
              </AppText>
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default SkillsAndAccomplishment;
