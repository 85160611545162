import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  skill: string;
}

const SkillCard: React.FC<Props> = ({ skill, ...rest }) => {
  return <Box {...rest}>{`\u2022 ${skill}`}</Box>;
};

export default SkillCard;
