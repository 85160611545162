import jsPDF from 'jspdf';
import { Experience } from 'types/User';
import { addParagraphText } from 'utills/jsPdf';

export const experienceItem = (
  experience: Experience,
  doc: jsPDF,
  startX: number,
  startY: number
) => {
  let nextLine = startY;

  doc.setFontSize(10);
  doc.text(`${experience.designation}`, startX, nextLine, {
    align: 'left'
  });
  doc.setFontSize(8);

  nextLine += 20;

  if (experience.present) {
    doc.text(`${experience.startYear} - Present ${experience.companyName}`, startX, nextLine, {
      align: 'left'
    });
  } else {
    doc.text(
      `${experience.startYear} - ${experience.endYear} ${experience.companyName}`,
      startX,
      nextLine,
      {
        align: 'left'
      }
    );
  }

  nextLine += 20;

  nextLine = addParagraphText(
    `${experience.moredetails}`,
    'justify',
    startX,
    nextLine,
    300,
    15,
    70,
    doc
  );

  return nextLine;
};
